@media (max-width: 1199px){
    table thead tr th {
        font-size: 16px;
        padding: 10px 30px;
    }
    table tbody tr td {
        font-size: 16px;
        padding: 10px 30px;
    }
    .xp-navigation .nav > li > a {
        font-size: 20px;
        padding: 15px 18px;
    }
    .navigation ul li a {
        font-size: 20px;
        padding: 0 16px;
    }
    table tbody tr td:last-child a {
        margin-right: 15px;
        font-size: 16px;
    }
}
@media (max-width: 930px){
    .xp-footer .webclix{
        position: relative;
        right: 10px;
        top: 10px;
    }
}
@media (max-width: 991px){
    .xp-navigation .nav > li > a {
        font-size: 16px;
        padding: 15px 12px;
    }
    .navigation ul li a {
        font-size: 16px;
        padding: 0 7px;
    }
    .xp-headre-v2 .xp-logo {
        width: 90px;
    }
    .xp-headre-v2 .navigation {
        padding: 33px 0;
    }
    .xp-geplande-workshop-right {
        width: 100%;
    }
    .xp-blog-left .text-box {
        bottom: 25px;
        padding: 0 10px;
    }
    table tbody tr td {
        font-size: 14px;
        padding: 10px 15px;
    }
    table thead tr th {
        font-size: 17px;
        padding: 10px 15px;
    }
    .image-map{
        display: none;
    }
    .mobail-home{
        display: block;
        height: 100vh;
    }
    .khojasse-header-mid .logo {
        width: 105px;
    }
	.xp-geplande-workshop-right {
    margin-top: 50px;
}
}
@media (max-width: 767px){
    .khojasse-header-top {
        padding: 0 5%;
    }
    .khojasse-header-last {
        bottom: 2%;
        padding: 0 5%;
    }
    table {
        width: 900px;
    }
    .xp-logo {
        position: relative;
        z-index: 1;
    }
    .xp-navigation {
        padding: 19px 0;
        position: absolute;
        right: 0;
        top: -86px;
        width: 100%;
    }
    .xp-navigation .navbar-default .navbar-collapse, .navbar-default .navbar-form {
        background-color: #ffffff;
        border-color: #e7e7e7;
        position: absolute;
        top: 73px;
        width: 100%;
        z-index: 1;
    }
    .xp-navigation .navbar-collapse {
        padding: 0 15px;
    }
    .xp-navigation .navbar-toggle {
        background-color: #f8daa4;
        background-image: none;
        border: none;
        border-radius: 0px;
    }
    .xp-navigation .navbar-default .navbar-toggle .icon-bar{
        background-color: #fff;
    }
    .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover{
        background-color: #f8daa4;
    }
    .xp-geplande-workshop-right{
        margin-top: 50px;
    }
    .xp-blog-right{
        margin-top: 30px;
    }
    .blo-recent-inner{
        margin-bottom: 20px;
    }
    .xp-main-contant {
        padding: 100px 0 50px;
    }
    .xp-blog .col-md-6{
        padding-left: 15px;
        background-color: transparent;
    }
    .xp-blog .slimScrollDiv .slimScrollBar {
        right: 5px !important;
    }
    .khojasse-header-mid .logo {
        margin: 39px 0 0 70px;
    }
}
@media (max-width: 600px){
    .khojasse-header-top a {
        font-size: 16px;
        height: 79px;
        padding: 29px 0;
        width: 140px;
    }
    .khojasse-header-top a:last-child {
        height: 55px;
        padding: 16px 0;
        width: 130px;
    }
    .khojasse-header-last a:first-child {
        font-size: 16px;
        height: 60px;
        padding: 19px 0;
        width: 110px;
    }
    .khojasse-header-last a:nth-child(2) {
        font-size: 16px;
        height: 43px;
        padding: 10px 0;
        right: 0;
        width: 110px;
    }
    .khojasse-header-last a:last-child {
        font-size: 16px;
        height: 47px;
        padding: 13px 0;
        width: 110px;
    }
    .khojasse-header-mid .home-link a {
        font-size: 16px;
        height: 48px;
        padding: 14px 0;
        width: 110px;
    }
    .blog-detail-inner-trip h1 {
        font-size: 28px;
        padding-bottom: 5px;
    }
    .blog-banner {
        height: 200px;
    }
    .xp-contant-adress .map-contant{
        width: 100%;
        float: none;
    }
    .xp-contant-adress .xp-contant-locatrion{
        width: 100%;
        float: none;
        padding: 30px 25px;
    }
}
@media (max-width: 479px){
    .xp-geplande-innar .nav-tabs > li > a {
        font-size: 16px;
        margin-right: 10px;
        padding: 7px 20px;
    }
    .xp-geplande-button a {
        font-size: 16px;
        margin-right: 10px;
        padding: 7px 15px;
    }
    .xp-geplande-button a:last-child{
        margin-right: 0;
    }
    .xp-footer p {
        font-size: 14px;
        padding: 20px 0;
    }
    .xp-geplande-workshop-left,
    .xp-geplande-workshop-right{
        padding: 15px 15px 0;
    }
    h2 {
        font-size: 22px;
        padding-bottom: 15px;
    }
    .workshop-contant {
        padding-bottom: 16px;
    }
    p{
        font-size: 14px;
    }
    .blog-recent .col-md-4{
        width: 100%;
    }
    .xp-blog-left .image-holder img {
        height: 300px;
    }
}
@media (max-width: 379px){
    .khojasse-header-top {
        top: 1%;
    }
    .khojasse-header-mid {
        top: 46%;
        transform: translateY(-46%);
    }
    .khojasse-header-last {
        bottom: 16%;
    }
    .khojasse-header-last a:nth-child(2) {
        left: auto;
        margin: 0;
        position: absolute;
        right: 16px;
    }
    .khojasse-header-last a:last-child {
        bottom: -66px;
        left: 0;
        margin: 0 auto;
        position: absolute;
        right: 0;
    }
    .xp-gallery .col-xs-6{
        width: 100%;
    }
}






















